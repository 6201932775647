import axios from 'axios';
import localStorage, { ID_TOKEN, ACCESS_TOKEN, REFRESH_TOKEN } from 'utils/localStorage';
import { isJsonString } from './utils';
import { store } from '../index';
import { RESET_ALL_AFTER_LOGOUT } from 'utils/constant';
import { apiUrl } from 'api/utils';

export const REFRESH_TOKEN_URL = apiUrl('auth/token/refresh');

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL, // Replace with your API base URL
    headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept-Encoding': 'gzip, deflate, br',
        'Connection': 'keep-alive',
        'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
    }
});

axiosInstance.interceptors.request.use(
    config => {
        if (!window.navigator.onLine) {
            return Promise.reject(new Error('No internet connection'));
        }

        const idToken = localStorage.load('idToken');
        const refreshToken = localStorage.load('refreshToken');

        if (idToken) {
            config.headers['Authorization'] = idToken;
        }
        if (refreshToken) {
            config.headers['refreshToken'] = refreshToken;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
      return isJsonString(response.data) ? JSON.parse(response.data) : response.data;
    },
    async error => {
        if (!error.response) {
            return Promise.reject(new Error('Network Error'));
        }
        const { response } = error;
        const originalRequest = error.config;
        const responseText = response.data;

        switch (response.status) {
          case 200:
          case 201:
          case 204: {
              return isJsonString(responseText) ? JSON.parse(responseText) : {};
          }
          case 401: {
            if (originalRequest._retry) {
                if (window.location.pathname !== '/login') {
                    localStorage.clear(ID_TOKEN);
                    localStorage.clear(ACCESS_TOKEN);
                    localStorage.clear(REFRESH_TOKEN);
                    store.dispatch({ type: RESET_ALL_AFTER_LOGOUT });
                    window.location.replace('/login');
                } else {
                    const error = isJsonString(responseText)
                        ? JSON.parse(responseText)
                        : { error_message: 'Server error' };
                    throw new Error(error?.error_message || error?.message);
                }
          } else {
                originalRequest._retry = true;
                try {
                    const tokenResponse = await axiosInstance.get(REFRESH_TOKEN_URL);
                    const newToken = tokenResponse.AuthenticationResult.IdToken;

                    localStorage.save(ID_TOKEN, newToken);
                    axios.defaults.headers.common['Authorization'] = newToken;
                    originalRequest.headers['Authorization'] = newToken;

                    return axiosInstance(originalRequest);
                } catch (refreshError) {
                      if (window.location.pathname !== '/login') {
                          localStorage.clear(ID_TOKEN);
                          localStorage.clear(ACCESS_TOKEN);
                          localStorage.clear(REFRESH_TOKEN);
                          store.dispatch({ type: RESET_ALL_AFTER_LOGOUT });
                          window.location.replace('/login');
                      } else {
                          const error = isJsonString(refreshError.response.data)
                              ? JSON.parse(refreshError.response.data)
                              : { error_message: 'Server error' };
                          throw new Error(error?.error_message || error?.message);
                      }
                }
          }
              break;
          }
          default: {
              const error = responseText
                  ? responseText
                  : { error_message: 'Server error' };
              throw new Error(error?.error_message || error?.message);
          }
      }
    }
);

export default axiosInstance;