import { apiUrl } from 'api/utils';
import localStorage, { ID_TOKEN, ACCESS_TOKEN, REFRESH_TOKEN } from 'utils/localStorage';
import { store } from '../../index';
import { RESET_ALL_AFTER_LOGOUT } from 'utils/constant';
import axiosInstance from '../base';

export const LOGIN_URL = apiUrl('auth/token');
export const SIGNUP_URL = apiUrl('user/sign-up');
export const LOGOUT_URL = apiUrl('auth/revoke');
export const CHANGE_PASSWORD_FIRSTTIME_URL = apiUrl('auth/new-password-required');
export const CHANGE_PASSWORD_URL = apiUrl('auth/change-password');
export const RESET_PASSWORD_URL = apiUrl('auth/reset-password');
export const VERIFY_REGISTRATION_ID = apiUrl('user/sign-up/verify-registration-id');
export const VERIFY_EMAIL_SIGNUP = apiUrl('user/verify-email');
export const VERIFY_EMAIL_CHANGE_PASSWORD = apiUrl('auth/change-password');

export default {
  login: async ({ email, password }) => {
    const data = await axiosInstance.post(LOGIN_URL, {email, password});

    const isUser = data?.Profile
      ? data?.Profile['cognito:groups']?.includes('user')
      : false;

    return {
      isUser: isUser,
      challengeName: data?.ChallengeName,
      session: data?.Session,
      idToken: data?.AuthenticationResult?.IdToken,
      accessToken: data?.AuthenticationResult?.AccessToken,
      refreshToken: data?.AuthenticationResult?.RefreshToken,
      userProfile: data?.Profile,
      num_reserve: data.num_reserve
    };
  },
  verify_registration_id: async ({ first_number, last_number }) => {
    const registration_id = first_number + '-' + last_number;
    const data = await axiosInstance.get(`${VERIFY_REGISTRATION_ID}/${registration_id}`);
    return data;
  },
  signup: async ({
    company_name,
    email,
    password,
    customer_type_id,
    name,
    zip_code,
    province,
    city,
    street,
    landline_number,
    mobile_phone_number,
    is_notify,
    registration_id
  }) => {
    return await axiosInstance.post(SIGNUP_URL, {
      company_name,
      email,
      password,
      customer_type_id,
      name,
      zip_code,
      province,
      city,
      street,
      landline_number,
      mobile_phone_number,
      is_notify,
      registration_id
    });

  },
  verify_email_signup: async ({ email, password }) => {
    return await axiosInstance.post(`${VERIFY_EMAIL_SIGNUP}`, {email, password});
  },
  confirm_verify_email: async ({ access_token, email_code, email }) => {
    return await axiosInstance.put(`${VERIFY_EMAIL_SIGNUP}`, {access_token, email_code, email});

  },
  logout: async () => {
    const access_token = localStorage.load(ACCESS_TOKEN);
    const refresh_token = localStorage.load(REFRESH_TOKEN);
    await axiosInstance.post(LOGOUT_URL, {access_token, refresh_token});

    localStorage.clear(ID_TOKEN);
    localStorage.clear(ACCESS_TOKEN);
    store.dispatch({ type: RESET_ALL_AFTER_LOGOUT });
  },
  changePassword: async ({ email, password, session }) => {
    return await axiosInstance.put(CHANGE_PASSWORD_FIRSTTIME_URL, {
      session,
      email,
      password
    });
  },
  userChangePassword: async ({ email, old_password, new_password, access_token }) => {
    return await axiosInstance.put(CHANGE_PASSWORD_URL, {
      email,
      old_password,
      new_password,
      access_token
    });
  },
  userChangedPassword: async ({ email, old_password, new_password }) => {
    return await axiosInstance.post(CHANGE_PASSWORD_URL, {
      email,
      old_password,
      new_password,
    });
  },
  forgotPassword: async ({ email, phone }) => {
    return await axiosInstance.put(`${RESET_PASSWORD_URL}/${email}`, {...phone});
  },
  resetPassword: async ({ email, code, password }) => {
    return await axiosInstance.post(RESET_PASSWORD_URL, {
      email,
      email_code: code,
      new_password: password
    });
  },
  confirm_verify_change_password: async ({ email, email_code }) => {
    return await axiosInstance.put(`${VERIFY_EMAIL_CHANGE_PASSWORD}`, {
      email, email_code
    });
  },
};
