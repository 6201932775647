import { apiUrl } from 'api/utils';
import axiosInstance from './base';

export const REQUEST_SITUATION = apiUrl('support/request-situation');
export const USER_REQUESTED = apiUrl('user/support');
export const USER_INFORMATION = apiUrl('user/profile');
export const TOP_INFORMATION = apiUrl('user/dashboard');
export const USER_EDIT = apiUrl('user/profile');
export const USER_RESERVE_ADDRESS = apiUrl('user/reserve_address');

export default {
  getRequestSituations: async () => {
    return await axiosInstance.get(REQUEST_SITUATION + '/view');
  },
  cancelRequest: (body, isSingle) => {
    return axiosInstance.put(REQUEST_SITUATION + `/cancel-request`, {
      ...(isSingle
      ? { body: JSON.stringify(body) }
      : { body: JSON.stringify({ cancel_message: body.cancel_message }) })});
  },

  getUserRequested: (tab) => {
    return axiosInstance.get(USER_REQUESTED + `/` + tab);

  },
  acceptUserRequested: (id) => {
    return axiosInstance.post(USER_REQUESTED + '/accept/' + id);
  },
  rejectUserRequested: (id) => {
    return axiosInstance.put(USER_REQUESTED + '/deny/' + id);

  },
  getUserInfo: async () => {
    const data = await axiosInstance.get(USER_INFORMATION);
    return data;
  },
  getTopInfo: async () => {
    const data = await axiosInstance.get(TOP_INFORMATION);
    return data;
  },
  updateUserInfo: (body) => {
    return axiosInstance.put(USER_EDIT, body);
  },
  getReserveAddress: async () => {
    const data = await axiosInstance.get(USER_RESERVE_ADDRESS);
    return data;
  },
};
