import { apiUrl } from 'api/utils';
import moment from '../../../node_modules/moment/moment';
import axiosInstance from '../base';

export const RESERVE_INFO_URL = apiUrl('user/waters');
export const RESERVE_DETAIL_URL = apiUrl(`user/water`);
export const CREATE_RESERVE_URL = apiUrl(`user/water`);

export default {
  getReserveInfo: async () => {
    const data = await axiosInstance.get(RESERVE_INFO_URL);
    return data;
  },
  getReserveDetail: async (reserve_id) => {
    const data = await axiosInstance.get(`${RESERVE_DETAIL_URL}/${reserve_id}`);
    return data;
  },
  deleteReserveDetail: async (reserve_id) => {
    const data = await axiosInstance.delete(`${RESERVE_DETAIL_URL}/${reserve_id}`);
    return data;
  },
  createNewReserve: async (body) => {
    const jsonBody = {
      item_type: body.item_type,
      item_name: body.item_name !== "その他" ? body.item_name : body.other_name,
      registration_id: body.registration_id,
      quantity: Number(body.quantity),
      item_unit: body.item_unit,
      exp_date: body.exp_date._d.getTime().toString(),
      zip_code: body.zip_code,
      province: body.province,
      city: body.city,
      street: body.street,
      is_published: body.is_published,
      wrn_month: body.wrn_month,
      person_in_charge: body.person_in_charge.map(per => {
        return {
          name: per.name,
          email: per.email,
          mobile_phone_number: per.mobile_phone_number,
          landline_number: per.landline_number,
          is_notify: per.is_notify
        };
      }),
    };

    const data = await axiosInstance.post(CREATE_RESERVE_URL, {...jsonBody});
    return data;
  },
  editReserveDetail: async (body) => {
    const jsonBody = {
      reserve_id: body.reserve_id,
      item_type: body.item_type,
      item_name: body.item_name !== "その他" ? body.item_name : body.other_name,
      registration_id: body.registration_id,
      quantity: Number(body.quantity),
      item_unit: body.item_unit,
      exp_date: moment(body.exp_date)._d.getTime().toString(),
      zip_code: body.zip_code,
      province: body.province,
      city: body.city,
      street: body.street,
      wrn_month: body.wrn_month,
      is_published: body.is_published,
      person_in_charge: body.person_in_charge.map(per => {
        return {
          name: per.name,
          email: per.email,
          mobile_phone_number: per.mobile_phone_number,
          landline_number: per.landline_number,
          is_notify: per.is_notify
        };
      }),
    };

    const data = await axiosInstance.put(`${RESERVE_DETAIL_URL}/${body.reserve_id}`, {...jsonBody});
    return data;
  }
};
